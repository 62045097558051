<template>
  <div class="menu-links">
    <div class="menu-link retour-style">
      <router-link class="menu-item" to="/cdg">
        <img
          src="../../assets/Icon ionic-md-arrow-dropright-circle.png"
          class="flesh-icon"
        />
        <span>Retour</span></router-link
      >
    </div>
    <div class="menu-link">
      <router-link class="menu-item" to="/setting/personal-info">
        Informations personnelles</router-link
      >
    </div>
    <div class="menu-link" v-if="isAdminRegie">
      <router-link class="menu-item" to="/setting/regie-info">
        Informations de régie
      </router-link>
    </div>
    <div class="menu-link" v-if="isAdminRegie">
      <router-link class="menu-item" to="/setting/file">
        Liste des documents
      </router-link>
    </div>
    <div class="menu-link" v-if="checkPermission('GFTH')">
      <router-link class="menu-item" to="/setting/configurationfiche"
        >Gestion fiche</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('CTTH')">
      <router-link class="menu-item" to="/setting/calender-th"
        >Calendrier TH
      </router-link>
    </div>
    <custom-drop-down
      v-if="permisssionGestionFrais"
      title="Gestion des variable"
      :items="itemsGestionVariable"
    ></custom-drop-down>
    <!-- <div class="menu-link" v-if="checkPermission('RZTH')">
      <router-link class="menu-item" to="/setting/repartition-zone-th"
        >Répartition des zones
      </router-link>
    </div> -->
    <div class="menu-link" v-if="checkPermission('GS')">
      <router-link class="menu-item" to="/setting/gestion-filiale-th"
        >Gestion des sociétés
      </router-link>
    </div>
    <div class="menu-link" v-if="checkPermission('GCP')">
      <router-link class="menu-item" to="/setting/gestion-client-passage"
        >Gestion des clients passager
      </router-link>
    </div>
    <div class="menu-link" v-if="checkPermission('GGBA')">
      <router-link class="menu-item" to="/setting/gestion-banque-th"
        >Gestion des banques
      </router-link>
    </div>

    <div class="menu-link" v-if="checkPermission('GOTH')">
      <router-link class="menu-item" to="/setting/organisme-new-th">
        Gestion des organismes
      </router-link>
    </div>
    <custom-drop-down
      v-if="permisssionTemplates"
      title="Gestion des templates"
      :items="itemsGestionTemplates"
    ></custom-drop-down>
    <div class="menu-link">
      <router-link
        v-if="checkPermission('GUTH')"
        class="menu-item"
        :class="{ 'router-link-exact-active': computedRouterUserActive }"
        to="/setting/users"
        >Gestion des utilisateurs</router-link
      >
    </div>
    <custom-drop-down
      v-if="permisssionFactureLibre"
      title="Gestion des factures libre"
      :items="itemsGestionFactureLibre"
    ></custom-drop-down>
    <div class="menu-link" v-if="checkPermission('GVTH')">
      <router-link class="menu-item" to="/setting/vue"
        >Gestion des vues</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('GMETH')">
      <router-link class="menu-item" to="/setting/modele-export"
        >Gestion des modèles exportation</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('GBHT')">
      <router-link class="menu-item" to="/setting/gestion-des-biens"
        >Gestion des biens</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('GTSFL')">
      <router-link class="menu-item" to="/setting/gestion-type-societe"
        >Gestion type des sociétes</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('GCM')">
      <router-link class="menu-item" to="/setting/configurationMensuel"
        >Gestion configuration mensuel</router-link
      >
    </div>
    <div class="menu-link" v-if="checkPermission('GSHT')">
      <router-link class="menu-item" to="/setting/gestion-services"
        >Gestion des services</router-link
      >
    </div>
    <div
      class="menu-link"
      v-if="checkPermission('GTPHT') || checkPermission('GCPHT')"
    >
      <router-link
        class="menu-item"
        :to="
          checkPermission('GCPHT')
            ? '/setting/configuration-paiement/gestion-conditions-paiement'
            : checkPermission('GTPHT')
            ? '/setting/configuration-paiement/gestion-type-reglement'
            : '/setting/configuration-paiement'
        "
        >Configuration du paiement
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import customDropDown from '../component/customDropDown.vue';
export default {
  components: { customDropDown },
  data() {
    return {
      itemsGestionVariable: [
        {
          name: 'Gestion des configurations',
          href: '/setting/gestion-configuration',
          code: ['GLCTH']
        }
      ],
      itemsGestionFactureLibre: [
        {
          name: 'Gestion des catégories',
          href: '/setting/gestion-categories',
          code: 'GCATTH'
        },
        {
          name: 'Gestion des produits',
          href: '/setting/gestion-produit',
          code: 'GPTH'
        }
      ],
      itemsGestionTemplates: [
        {
          name: 'Templates',
          href: '/setting/template-th',
          code: 'GTTH'
        },
        {
          name: 'Gestion des groupes',
          href: '/setting/groupe-template-th',
          code: 'GGPTTH'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['getUserData', 'checkPermission']),
    permisssionGestionFrais() {
      return this.checkPermission('GLCTH');
    },
    permisssionFactureLibre() {
      return this.checkPermission('GCATTH') || this.checkPermission('GPTH');
    },
    permisssionTemplates() {
      return this.checkPermission('GTTH') || this.checkPermission('GGPTTH');
    },
    computedRouterUserActive() {
      return (
        this.$route.path === '/setting/users/permissions' ||
        this.$route.path === '/setting/users/profils' ||
        this.$route.path === '/setting/users/familles' ||
        this.$route.path === '/setting/users/gestion-societe-permission' ||
        this.$route.path === '/setting/users/users'
      );
    },
    computedIsoCurenType: function() {
      return false;
    },
    isSuperAdmin: function() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin'
      );
    },
    isAdminRegie: function() {
      return (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'admin_regie'
      );
    }
  }
};
</script>
<style scoped lang="scss">
.menu-links {
  border-radius: 25px;
  margin: -3px 0px;
  // padding: 0px 0px 6px 4px;

  .retour-style {
    span {
      margin-left: 10px;
    }
  }
  .menu-link {
    font-size: 12px;
    .flesh-icon {
      width: 23px;
    }
    a.menu-item {
      border-radius: 21px 0px 0px 21px;
      display: block;
      font-size: 12px;
      font-weight: 500;
      color: #495057;
      margin: 4px 0;
      padding: 5px 10px;
      text-decoration: none;
      background-color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      &.router-link-exact-active {
        color: #fff;
        background-color: #4d4bac;
      }
      &:hover {
        color: #fff;
        background-color: #9799d6;
      }
      span {
        margin-top: 2px;
      }
    }
  }
}
</style>
